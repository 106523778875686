.profile_pet {
width: 116px;
height: 116px;
padding: 10px;

}

ion-item {
    margin: -5px;
}

.bt_fechar {
   height: 30px;
   width: 100px;
}