
.menu_background {
--background: url('../theme/menu_background.jpg') 0 0/100% 100% no-repeat;
background-size: cover;
}

ion-card {
color: black;
padding: 5px;
box-shadow: 10px 10px 50px black;
}

.spa {
    position: relative;
    top: 50%;
    
}