ion-content {
--background: url('../theme/background.jpg') 0 0/100% 100% cover no-repeat;
background-size: 100% 100%;
}

.espaco_login {
    position: relative;
    top: 5%;
 

}

ion-card {
    color: black;
    padding: 5px;
    box-shadow: 10px 10px 50px black;
    }

.card_login {
    padding: 5px;
}

.spa_cad {
    position: relative;
    top: 5%;
    
}