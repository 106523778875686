ion-content {
    --background: url('../theme/background.jpg') 0 0/100% 100% no-repeat;
    background-size: 100% 100%;
    }
    
    .espaco_login {
        position: relative;
        top: 20%;
    
    }
    
    ion-card {
        color: black;
        padding: 5px;
        box-shadow: 10px 10px 50px black;
        }
    
    .card_login {
        padding: 5px;
    }